import React from "react"
// import { Link } from "gatsby"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"

const Privacy = ({ location }) => {
  return (
    <Layout lang="en-US">
      <Seo
        pagetitle="Privacy Policy"
        pagepath={location.pathname}
        pagedesc="Privacy Policy | PARKLoT offers a Twitter campaign tool that can be run as many times as you want for a monthly fee."
      />
      <Breadcrumb title="Privacy Policy" />
      <main className="policy">
        <section className="policy__section l-container">
          <h1 className="policy__title">Privacy Policy</h1>
          <p className="align-R">
            Date of enactment December 1, 2021
            <br />
            Date of last revision December 1, 2021
            <br />
            <br />
          </p>
          <p className="policy__text">
            &nbsp;X-HACK Corporation (hereinafter referred to as "the Company")
            is engaged in the business of supporting the
            construction/implementation of e-commerce sites and contracted
            development of IT systems, and recognizes that the protection of
            personal information of customers, business partners, employees, and
            other related parties is a serious responsibility. Therefore, based
            on the philosophy of personal information protection and our own
            code of conduct, we are fully aware of our social mission, protect
            the rights of individuals, and comply with laws and regulations
            concerning personal information.
            <br />
            &nbsp;In addition, we hereby declare that we will establish a
            personal information protection management system to realize the
            following policy, and will make company-wide efforts to continuously
            improve the system while always being aware of the latest IT
            technology trends, changes in social demands, and changes in the
            business environment. We hereby declare that we will make
            company-wide efforts to continuously improve the system, while
            always being aware of the latest trends in information technology,
            changes in social demands and changes in the business environment.
          </p>
          <h2 className="policy__title2">Privacy Policy</h2>
          <ol className="policy__olist">
            <li className="policy__item">
              We will acquire, use, and provide personal information in an
              appropriate manner, and will not handle personal information
              beyond the scope necessary to achieve the specified purposes of
              use. We will also take measures to this end.
            </li>
            <li className="policy__item">
              We will comply with laws and regulations, national guidelines, and
              other norms regarding the handling of personal information.
            </li>
            <li className="policy__item">
              To prevent the risk of leakage, loss, or damage of personal
              information, we will continuously improve our personal information
              security system by injecting management resources that match the
              actual conditions of our business in order to prevent such risks
              through reasonable security measures. In the event of an
              emergency, corrective measures will be taken immediately.
            </li>
            <li className="policy__item">
              We will respond promptly and sincerely to complaints and
              consultations regarding the handling of personal information.
            </li>
            <li className="policy__item">
              The personal information protection management system will be
              reviewed and improved on an ongoing basis in a timely and
              appropriate manner in light of changes in the environment and
              actual conditions surrounding our company.
            </li>
          </ol>
          <p className="align-R">
            Shinsuke Matsuda, Representative Director, X-HACK Inc.
          </p>

          <div className="policy__revision">
            <h2 className="policy__title2">
              [Contact for inquiries regarding personal information protection
              policy and complaints and consultation regarding personal
              information]
            </h2>
            <p className="policy__text">
              　　　　3F-251 Minowa Omotesando, 3-15-9 Minami-Aoyama, Minato-ku,
              Tokyo 107-0062, Japan
              <br />
              　　　　X-HACK Corporation Personal Information Inquiry Desk
              <br />
              　　　　E-mail address: smatsuda@x-hack.jp
            </p>
          </div>

          <h2 className="policy__title2">
            Publication of the purpose of use of personal information
          </h2>
          <p className="policy__text">
            &nbsp;X-HACK Corporation (hereinafter referred to as "the Company")
            publicly announces that it will use personal information within the
            scope of the following purposes of use, in accordance with Article
            18.1 of the Act on the Protection of Personal Information (Act on
            the Protection of Personal Information).
          </p>
          <h3 className="infotmation__title3">
            Purpose of use of personal information
          </h3>
          <p className="policy__text">
            We handle personal information for the following purposes
            <br />
            ・To provide information on our products and services
            <br />
            ・To provide appropriate after-sales service for products and
            services
            <br />
            ・Personal information of those who inquire about our businesses
            will be used to respond to their inquiries.
            <br />
            ・Personal information of our employees for personnel and labor
            management, business management, health management, etc.
            <br />
            ・For recruiting and communicating with applicants
            <br />
            ・PARKLOT subscribers' personal information is used for the
            provision and management of this service and for billing purposes.
            <br />
            ・PARKLOT" users' personal information is used to provide this
            service.
          </p>

          <h2 className="policy__title2">
            Dissemination of Matters Concerning Retained Personal Data
          </h2>
          <p className="policy__text">
            &nbsp;X-HACK Corporation (hereinafter referred to as "the Company"),
            the personal data in its possession (for which the Company has the
            authority to notify the purpose of use, disclose, correct, add or
            delete content, suspend use, erase, and suspend provision to third
            parties) are as follows.
          </p>
          <ol className="policy__olist">
            <li className="policy__item">
              Name, affiliation and contact information of the administrator
              regarding personal information
              <p className="policy__text">
                Administrator name: Shinsuke Matsuda, Personal Information
                Protection Manager
                <br />
                E-mail address: smatsuda@x-hack.jp
              </p>
            </li>
            <li className="policy__item">
              Purpose of Use of Retained Personal Data
              <p className="policy__text">
                ・To provide information about our products and services
                <br />
                ・To provide appropriate after-sales service for products and
                services
                <br />
                ・Personal information of those who inquire about our various
                businesses is used to respond to their inquiries.
                <br />
                ・Personal information of our employees is used for personnel
                and labor management, business management, health management,
                etc.
                <br />
                ・For recruiting and communicating with applicants
              </p>
            </li>
            <li className="policy__item">
              Contact for complaints regarding retained personal data
              <p className="policy__text">
                Inquiries regarding retained personal data are accepted at the
                following contact point.
                <br />
                　　　　3F-251 Minowa Omotesando, 3-15-9 Minami-Aoyama,
                Minato-ku, Tokyo 107-0062, Japan
                <br />
                　　　　X-HACK Corporation Personal Information Inquiry Desk
                <br />
                　　　　E-mail address: smatsuda@x-hack.jp
              </p>
            </li>
            <li className="policy__item">
              About the authorized personal information protection organizations
              we are a member of
              <p className="policy__text">
                There is no authorized personal information protection
                organization that we are a member of.
              </p>
            </li>
          </ol>

          <h2 className="policy__title2">
            Procedures for Disclosure, etc. of Retained Personal Data
          </h2>
          <p className="policy__text">
            &nbsp;You can request "notification of purpose of use, disclosure,
            correction, addition or deletion of content, suspension of use,
            elimination, and suspension of provision to third parties"
            (disclosure, etc.) of retained personal data.
            <br />
            &nbsp;We will respond to requests for disclosure of personal
            information in accordance with the following procedures.
          </p>

          <ol className="policy__olist">
            <li className="policy__item">
              Acceptance of Requests for Disclosure, etc.
              <p className="policy__text">
                (1) Please contact us at the address below.
                <br />
                Please mail or bring the designated form to the following
                address after agreeing to our "Handling of Personal Information"
                upon arrival.
                <br />
                　　　　3F-251 Minowa Omotesando, 3-15-9 Minami-Aoyama,
                Minato-ku, Tokyo 107-0062, Japan
                <br />
                　　　　X-HACK Corporation Personal Information Inquiry Desk
                <br />
                　　　　E-mail address: smatsuda@x-hack.jp
                <br />
                <br />
                (2) Please mail or bring one of the following documents together
                with (1) "Prescribed Form" for identification.
                <br />
                ・A copy of an official identification such as a driver's
                license or passport that verifies the identity of the person
                making the request for disclosure, etc. (the copy must show the
                name and address of the person making the request for
                disclosure, etc.)
                <br />
                ・A copy of the certificate of residence (made within 30 days
                prior to the date of the request for disclosure, etc.)
                <br />
                <br />
                (3) If a proxy is to complete the procedures, in addition to (1)
                the "prescribed form" and (2) "identification documents," please
                mail or bring one of the documents listed in (a) and (b) below.
                <br />
                a) Identification documents of the agent
                <br />
                ・A document certifying the agent, a copy of the agent's
                driver's license, passport, or other official identification
                that can be used to verify the agent's identity (the document
                must show the name and address of the agent making the request
                for disclosure, etc.)
                <br />
                ・A copy of the representative's certificate of residence (made
                within 30 days prior to the date of the request for disclosure,
                etc.)
                <br />
                ・If the representative is an attorney, a document showing
                his/her registration number
                <br />
                (b) A document evidencing the power of attorney (power of
                attorney, etc.)
                <br />
                ・If the document proving the identity of the applicant or
                his/her representative clearly indicates the place of residence,
                it may be erased.
                <br />
                ・Documents proving the identity of the applicant or his/her
                representative will be disposed of responsibly within two months
                of our obtaining them.
              </p>
            </li>
            <li className="policy__item">
              Fees for "Requests for Disclosure, etc." and Method of Collection
              <p className="policy__text">
                Only in the case of a request for notification or disclosure of
                the purpose of use, the following amount (including our reply
                fee) will be charged per request. Please enclose a postal
                fixed-sum money order for the following amount when mailing the
                request form.
                <br />
                ・Commission amount: 1,000 yen (tax included)
              </p>
            </li>
            <li className="policy__item">
              Notification of review results
              <p className="policy__text">
                The results of our internal review will be communicated without
                delay by
                <br />
                ・In case of notification or disclosure of the purpose of use of
                personal information, a letter is mailed or hand-delivered.
                <br />
                ・For correction, notification, deletion, suspension of use,
                erasure, or suspension of provision to a third party of personal
                information, please contact us by phone or e-mail.
                <br />
                <br />
                The letter should be sent by one of the following methods,
                whichever is appropriate
                <br />
                ・The response letter will be mailed to the registered address
                of the applicant.
                <br />
                ・The response will be emailed to the person's registered email
                address.
                <br />
                ・Call the person's registered telephone number and answer
                verbally.
              </p>
            </li>
          </ol>

          <h2 className="policy__title2" id="privacy_policy">
            Handling of Personal Information
          </h2>
          <p className="policy__text">
            &nbsp;We collect and store personal information in accordance with
            A.3.4.2.5 of JIS Q 15001:2017 (A.3.4.2.4, Measures to be taken when
            information is obtained directly from the person in writing).
            <br />
            &nbsp;The personal information obtained from this form will be used
            only for the purposes described in section 3 below, and will not be
            used beyond the scope of these purposes.
          </p>
          <ol className="policy__olist">
            <li className="policy__item">
              Business Name
              <p className="policy__text">X-HACK Corporation</p>
            </li>
            <li className="policy__item">
              Name, affiliation and contact information of the administrator
              regarding personal information
              <p className="policy__text">
                Personal Information Protection Manager: Shinsuke Matsuda
                <br />
                Email Address: smatsuda@x-hack.jp
              </p>
            </li>
            <li className="policy__item">
              Purpose of use of personal information
              <p className="policy__text">・To respond to inquiries</p>
            </li>
            <li className="policy__item">
              Provision of Personal Information to Third Parties
              <p className="policy__text">
                We will not disclose or provide personal information provided by
                you to third parties except in the following cases
                <br />
                ・If you have the consent of the person in question
                <br />
                ・When required by law
                <br />
                ・When it is necessary for the protection of the life, body, or
                property of an individual and it is difficult to obtain the
                consent of the individual
                <br />
                ・When it is especially necessary for improving public health or
                promoting the sound growth of children and it is difficult to
                obtain the consent of the person in question.
                <br />
                ・When it is necessary to cooperate with a national agency, a
                local government, or an individual or entity entrusted by either
                a national agency or local government to execute affairs
                prescribed by law, and obtaining the consent of the individual
                is likely to impede the execution of such affairs.
              </p>
            </li>
            <li className="policy__item">
              Outsourcing the handling of personal information
              <p className="policy__text">
                We do not outsource the handling of personal information
                provided to us.
              </p>
            </li>
            <li className="policy__item">
              Request for Disclosure of Personal Information
              <p className="policy__text">
                Customers may contact our "Personal Information Inquiry Desk" to
                request disclosure of their personal information (notification
                of purpose of use, disclosure, correction, addition, deletion,
                suspension of use or elimination, or suspension of provision to
                a third party). In such cases, we will respond within a
                reasonable period of time after confirming the identity of the
                person making the request. For details on requests for
                disclosure, etc., please contact the "Personal Information
                Inquiry Desk" below.
                <br />
                <br />
                　　　　3F-251 Minowa Omotesando, 3-15-9 Minami-Aoyama,
                Minato-ku, Tokyo 107-0062, Japan
                <br />
                　　　　X-HACK Corporation Personal Information Inquiry Desk
                <br />
                　　　　Email Address: smatsuda@x-hack.jp
              </p>
            </li>
            <li className="policy__item">
              Voluntary nature of providing personal information
              <p className="policy__text">
                Whether or not you choose to provide us with your personal
                information is voluntary. However, if you do not provide us with
                the required information, we may not be able to provide you with
                the appropriate services.
              </p>
            </li>
            <li className="policy__item">
              Information obtained mechanically through access to this Web site
              <p className="policy__text">
                We may use cookies to obtain information about visitors to our
                website in order to ensure the security of the website and to
                improve user services.
              </p>
            </li>
          </ol>
        </section>
      </main>
    </Layout>
  )
}

export default Privacy
